import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import closeIcon from '../../static/img/close.svg';
import chatIcon from '../../static/img/chat.svg';
import phoneIcon from '../../static/img/phone.svg';
import loader from '../../static/img/loader.svg';

const Button = styled.div`
    background-color: #363534;
    position: fixed;
    bottom: 0.5em;
    right: ${({ right }) => right};
    height: 4em;
    width: 4em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 1.1em;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
        height: 3em;
        width: 3em;
        right: 0.5em;
        padding: 0.8em;
        bottom: ${({ bottomMobile }) => bottomMobile};
    }
`;

const Icon = styled.div`
    background-image: ${props =>
        props.isOpen ? `url(${closeIcon})` : `url(${props.icon})`};
    width: 100%;
    height: 100%;
`;

const Bar = styled.div`
    background-color: #363534;
    height: 4em;
    width: 100%;
    border-radius: 0.5em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    color: #fff;
    font-weight: 300;
`;

const FormContainer = styled.div`
    height: 32em;
    width: 20em;
    max-width: 90vw;
    max-height: 80vh;
    position: fixed;
    bottom: 5em;
    right: 1em;
    border: 1px solid #ccc;
    border-radius: 0.5em;
    background-color: #fff;
    transition-delay: 0.3s;
    transition: all 0.3s ease-in-out;
    transform: ${props =>
        props.isOpen ? 'translateX(0)' : 'translateX(50px)'};
    transition-delay: ${props => (props.isOpen ? props.delay : '0')};

    @media only screen and (max-width: 768px) {
        bottom: 4em;
    }
`;

const Telephone = styled.a`
    background-color: #363534;
    position: fixed;
    right: ${({ right }) => right};
    bottom: 1em;
    height: 2em;
    width: 7em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25em;
    text-decoration: none;
    font-weight: 500;
    color: white;
    border-radius: 0.5em;

    @media only screen and (max-width: 768px) {
        right: 3.25em;
        bottom: ${({ bottomMobile }) => bottomMobile};
    }
`;

const FieldWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 1em 0;
    margin-bottom: 1.5em;
    position: relative;
`;

const disabledStyles = {
    backgroundColor: '#ccc',
};

const sendStyles = {
    backgroundColor: '#4caf50',
};

const SubmitButton = styled.button`
    background-color: #363534;
    color: white;
    height: 3em;
    width: calc(100% - 2em);
    position: fixed;
    text-align: center;
    bottom: 1em;
    left: 1em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1em;
    font-weight: 100;
    border: none;

    ${props =>
        props.disabled
            ? css`
                  ${disabledStyles}
              `
            : ''}
    ${props =>
        props.isSend
            ? css`
                  ${sendStyles}
              `
            : ''}
`;

const inputStyles = {
    height: '2em',
    width: '100%',
    fontSize: '1em',
    padding: '0.5em',
    border: '1px solid #ccc',
    fontWeight: 100,
    position: 'relative',
};

const Loader = styled.img`
    height: 2em;
`;

const ContactForm = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTelOpen, setIsTelOpen] = useState(false);
    const [submitText, setSubmitText] = useState('Wyślij');
    const [isSend, setIsSend] = useState(false);

    return (
        <>
            <Button
                bottomMobile="0.5em"
                onClick={() => {
                    setIsOpen(!isOpen);
                    setSubmitText('Wyślij');
                    setIsSend(false);
                }}
                right="0.5em"
            >
                <Icon icon={chatIcon} isOpen={isOpen} />
            </Button>
            <Button
                bottomMobile="4em"
                onClick={() => setIsTelOpen(!isTelOpen)}
                right="5em"
            >
                <Icon icon={phoneIcon} isOpen={isTelOpen} />
            </Button>
            {isTelOpen && (
                <>
                    <Telephone
                        bottomMobile="4.75em"
                        href="tel:661791246"
                        right="8em"
                    >
                        661 791 246
                    </Telephone>
                    <Telephone
                        bottomMobile="2.25em"
                        href="tel:607796722"
                        right="16em"
                    >
                        607 796 722
                    </Telephone>
                </>
            )}
            {isOpen && (
                <FormContainer isOpen={isOpen}>
                    <Bar>Napisz do nas!</Bar>
                    <Formik
                        initialValues={{ email: '', text: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            fetch(
                                'https://mail-api-277515.oa.r.appspot.com/contact',
                                {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        replyTo: values.email,
                                        message: values.text,
                                    }),
                                }
                            )
                                .then(() => {
                                    setSubmitText('Wysłano!');
                                    setIsSend(true);
                                })
                                .catch(() => {
                                    setSubmitText('Spróbuj ponownie');
                                    setSubmitting(false);
                                });
                        }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = 'E-mail jest wymagany';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = 'Niepoprawny adres e-mail';
                            }
                            if (!values.text) {
                                errors.text = 'Wiadomość jest wymagana';
                            } else if (!/^.{3,}$/.test(values.text)) {
                                errors.text = 'Wymagane conajmniej 3 znaki';
                                return errors;
                            }
                            return errors;
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <FieldWrapper>
                                    <Field
                                        name="email"
                                        placeholder="Twój email"
                                        style={{
                                            ...inputStyles,
                                            marginTop: '1.5em',
                                        }}
                                        type="email"
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="email"
                                        style={{
                                            position: 'absolute',
                                            bottom: '-1.5em',
                                            left: '1em',
                                            color: 'red',
                                        }}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field
                                        as="textarea"
                                        name="text"
                                        placeholder="Twoja wiadomość"
                                        style={{
                                            ...inputStyles,
                                            resize: 'none',
                                            height: '8em',
                                        }}
                                        type="text"
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="text"
                                        style={{
                                            position: 'absolute',
                                            bottom: '-1.5em',
                                            left: '1em',
                                            color: 'red',
                                        }}
                                    />
                                </FieldWrapper>
                                <SubmitButton
                                    disabled={isSubmitting}
                                    isSend={isSend}
                                    type="submit"
                                >
                                    {(!isSubmitting || isSend) && submitText}
                                    {isSubmitting && !isSend && (
                                        <Loader alt="loader" src={loader} />
                                    )}
                                </SubmitButton>
                            </Form>
                        )}
                    </Formik>
                </FormContainer>
            )}
        </>
    );
};

export default ContactForm;
