import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import Footer from './Footer';
import Navbar from './Navbar';
import ContactForm from './ContactForm';
import './all.scss';
import useSiteMetadata from './SiteMetadata';

const TemplateWrapper = ({ children }) => {
    const { title, description } = useSiteMetadata();
    return (
        <div>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta content={description} name="description" />
                <link
                    href="https://fonts.googleapis.com/css?family=Josefin+Sans:100,300,400,500,700&display=swap&subset=latin-ext"
                    rel="stylesheet"
                />

                <link
                    href={`${withPrefix('/')}img/apple-touch-icon.png`}
                    rel="apple-touch-icon"
                    sizes="180x180"
                />
                <link
                    href={`${withPrefix('/')}img/favicon-32x32.png`}
                    rel="icon"
                    sizes="32x32"
                    type="image/png"
                />
                <link
                    href={`${withPrefix('/')}img/favicon-16x16.png`}
                    rel="icon"
                    sizes="16x16"
                    type="image/png"
                />

                <link
                    color="#ff4400"
                    href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
                    rel="mask-icon"
                />
                <meta content="#fff" name="theme-color" />

                <meta content="business.business" property="og:type" />
                <meta content={title} property="og:title" />
                <meta content="/" property="og:url" />
                <meta
                    content={`${withPrefix('/')}img/og-image.jpg`}
                    property="og:image"
                />
            </Helmet>
            <Navbar />
            {children}
            <ContactForm />
            <Footer />
        </div>
    );
};

TemplateWrapper.propTypes = {
    children: PropTypes.node,
};

export default TemplateWrapper;
