/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Logo from '../../static/img/logo.inline.svg';

const Nav = styled.nav`
    width: 100%;
    height: 4em;
    position: sticky;
    display: none;
    align-items: center;
    padding-top: 1em;
    box-sizing: border-box;
    top: 0;
    z-index: 1;
    transition: transform 0.3s ease, background-color 0.3s ease;
    background-color: ${props => (props.scrollY > 0 ? 'black' : 'white')};
    color: ${props => (props.scrollY > 0 ? 'white' : 'black')};
    transform: ${props => (props.scrollY > 0 ? 'translateY(-1em)' : 'none')};

    @media only screen and (min-width: 769px) {
        display: flex;
    }
`;

const List = styled.ul`
    list-style: none;
    box-sizing: border-box;
    margin: 0 3em;
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const LogoWrapper = styled.div`
    margin-left: 3em;
    svg {
        height: 2em;
        fill: ${props => (props.scrollY > 0 ? 'white' : 'black')};
    }
`;

const LogoWrapperMobile = styled.div`
    position: fixed;
    left: 1em;
    top: 0;
    z-index: 2;
    height: 2.5em;
    display: flex;
    align-items: center;
    svg {
        height: 1.1em;
        fill: white;
    }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const NavItem = styled(props => <Link {...props} />)`
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    position: relative;
    text-align: center;
    font-weight: 300;
    font-size: 0.8em;
    margin-right: 2em;
    height: 100%;

    &:after {
        content: '';
        position: absolute;
        background-color: ${props => (props.scrollY > 0 ? 'white' : 'black')};
        left: 0;
        bottom: 0;
        transform-origin: 0 50%;
        transform: scaleX(0);
        width: 100%;
        height: 1px;
        transition: all 0.2s ease-in;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }
`;

const NavMobile = styled.nav`
    @media only screen and (min-width: 769px) {
        display: none;
    }
`;

const activeMenuStyles = `
    height: 100vh;
    opacity: 1;
`;

const MobileNavPlaceholder = styled.div`
    height: 2.5em;
    @media only screen and (min-width: 769px) {
        display: none;
    }
`;

const MobileContainer = styled.div`
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    background-color: black;
    height: 2.5em;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    @media only screen and (min-width: 769px) {
        display: none;
    }

    ${props =>
        props.isActive
            ? css`
                  ${activeMenuStyles}
              `
            : ''}
`;

const ListMobile = styled.ul`
    list-style: none;
    box-sizing: border-box;
`;

const ListMobileContainer = styled.div`
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #141414;
    height: 100vh;
    display: flex;
    padding: 5em 0 0 3em;

    @media only screen and (min-width: 769px) {
        display: none;
    }

    ${props =>
        props.isActive
            ? css`
                  ${activeMenuStyles}
              `
            : ''}
`;

const activeHamburgerStyles = `
    transition: all 0.3s ease-in-out;
    transition-delay: 0.6s;
    transform: rotate(45deg);
`;

const Hamburger = styled.div`
    position: fixed;
    right: 0.5em;
    top: 0.5em;
    z-index: 2;
    &:hover {
        cursor: pointer;
    }

    ${props =>
        props.isActive
            ? css`
                  ${activeHamburgerStyles}
              `
            : ''}
`;

const Line = styled.span`
    width: 1.5em;
    height: 2px;
    background-color: #ecf0f1;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
`;

const Line1 = styled(Line)`
    ${props =>
        props.isActive
            ? css`
                  ${`
                    width: 0px;
                    transform: translateY(13px);
                  `}
              `
            : ''}
`;
const Line2 = styled(Line)`
    ${props =>
        props.isActive
            ? css`
                  ${`
                    transition-delay: 0.3s;
                  `}
              `
            : ''}
`;
const Line3 = styled(Line)`
    ${props =>
        props.isActive
            ? css`
                  ${`
                    transition-delay: 0.3s;
                    transform: translateY(-7px) rotate(90deg);
                  `}
              `
            : ''}
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const NavItemMobile = styled(props => <Link {...props} />)`
    display: block;
    text-decoration: none;
    color: white;
    font-size: 2em;
    position: relative;
    font-weight: 100;
    margin-bottom: 1em;
    transition: all 0.3s ease-in-out;
    transform: ${props =>
        props.isActive ? 'translateX(0)' : 'translateX(50px)'};
    transition-delay: ${props => (props.isActive ? props.delay : '0')};

    &:after {
        content: '';
        position: absolute;
        background-color: white;
        left: 0;
        bottom: 0;
        transform-origin: 0 50%;
        transform: scaleX(0);
        width: 100%;
        height: 1px;
        transition: all 0.2s ease-in;
    }

    &:hover {
        &:after {
            transform: scaleX(1);
        }
    }
`;

const Navbar = () => {
    const [scrollY, setScrollY] = useState(0);
    const [isActive, setIsActive] = useState(false);

    function setScroll() {
        setScrollY(window.pageYOffset);
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener('scroll', setScroll);
        }
        watchScroll();
        return () => {
            window.removeEventListener('scroll', setScroll);
        };
    }, []);

    return (
        <>
            <MobileNavPlaceholder />
            <NavMobile>
                <LogoWrapperMobile>
                    <Logo />
                </LogoWrapperMobile>
                <Hamburger
                    isActive={isActive}
                    onClick={() => setIsActive(!isActive)}
                >
                    <Line1 isActive={isActive} />
                    <Line2 isActive={isActive} />
                    <Line3 isActive={isActive} />
                </Hamburger>
                <MobileContainer isActive={isActive}>
                    <ListMobileContainer>
                        <ListMobile>
                            <li>
                                <NavItemMobile
                                    delay="0.3s"
                                    isActive={isActive}
                                    to="/"
                                >
                                    Home
                                </NavItemMobile>
                            </li>
                            <li>
                                <NavItemMobile
                                    delay="0.35s"
                                    isActive={isActive}
                                    to="/about"
                                >
                                    O nas
                                </NavItemMobile>
                            </li>
                            <li>
                                <NavItemMobile
                                    delay="0.4s"
                                    isActive={isActive}
                                    to="/blog"
                                >
                                    Realizacje
                                </NavItemMobile>
                            </li>
                            <li>
                                <NavItemMobile
                                    delay="0.45s"
                                    isActive={isActive}
                                    to="/contact"
                                >
                                    Kontakt
                                </NavItemMobile>
                            </li>
                        </ListMobile>
                    </ListMobileContainer>
                </MobileContainer>
            </NavMobile>
            <Nav scrollY={scrollY}>
                <LogoWrapper scrollY={scrollY}>
                    <Logo />
                </LogoWrapper>
                <List>
                    <li>
                        <NavItem scrollY={scrollY} to="/">
                            Home
                        </NavItem>
                    </li>
                    <li>
                        <NavItem scrollY={scrollY} to="/about">
                            O nas
                        </NavItem>
                    </li>
                    <li>
                        <NavItem scrollY={scrollY} to="/blog">
                            Realizacje
                        </NavItem>
                    </li>
                    <li>
                        <NavItem scrollY={scrollY} to="/contact">
                            Kontakt
                        </NavItem>
                    </li>
                </List>
            </Nav>
        </>
    );
};

export default Navbar;
