import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    background-color: #141414;
    text-align: center;
    padding: 3em 3em 1em 3em;
`;

const SocialMedia = styled.h3`
    color: #cab49b;
    margin-bottom: 1em;
    font-weight: 300;
    text-transform: uppercase;
`;

const Span = styled.span`
    display: block;
    text-decoration: none;
    color: #cab49b;

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
`;

const FooterLink = styled.a`
    text-decoration: none;
    color: #cecece;
    font-size: 1.2em;
    transition: background 0.3s;

    &:hover {
        color: white;
    }
`;

const InfoWrapper = styled.div`
    margin-top: 2em;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 992px) {
        flex-direction: column-reverse;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledLink = styled(props => <Link {...props} />)`
    text-decoration: none;
    color: #cab49b;
    font-size: 1.2em;
    transition: background 0.3s;

    &:hover {
        color: white;
    }

    @media only screen and (max-width: 992px) {
        margin-bottom: 2em;
    }
`;

const Footer = () => (
    <FooterContainer>
        <SocialMedia>Social Media</SocialMedia>
        <FooterLink href="https://www.facebook.com/renomakuchnie/">
            Facebook
        </FooterLink>
        <InfoWrapper>
            <Span>{`© ${new Date().getFullYear()} RenomaKuchnie.pl | Polska | Wykonanie: przemekdolata96@gmail.com`}</Span>
            <StyledLink to="/polityka-prywatnosci">
                Polityka prywatności
            </StyledLink>
        </InfoWrapper>
    </FooterContainer>
);

export default Footer;
